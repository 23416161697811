/*import React from "react"

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import Latest from '../components/component.latest';

const resources = require('../locales/translations.json');

const NotFoundPage = ({ location }) => {
	// strip / prefix and suffix:
	const locationPath = location.pathname.slice(1,-1);
	const locationPathLanguage = locationPath.split("/");
	const possibleLanguage = locationPathLanguage[0];

	let language = {
		"code": "EN",
	    "locale": "en_GB"
	}

	if(possibleLanguage.length == 2) {
		// eg. /es/ 
		language.code = possibleLanguage.toUpperCase();
		switch(language.code) {
			case "EN":
				language.locale = "en_GB";
			break;
			case "DE":
				language.locale = "de_DE";
			break;
			case "ES":
				language.locale = "es_ES";
			break;
			case "FR":
				language.locale = "fr_FR";
			break;
			case "CS":
				language.locale = "cs_CZ";
			break;
			case "ZH":
				language.locale = "zh_CN";
			break;
		}
	}

	let pageLanguage = language.code;

	const pageLanguageKey = language.code.toLowerCase();

	const translations = [];
	const slug = "404";

	return (
		<Layout language={language} translations={translations}>
		  	<SEO title={resources[pageLanguageKey].translation["not-found"]["seo-title"]} translations={translations} slug={slug} language={language}  />
		  	<HeroBanner title={resources[pageLanguageKey].translation["not-found"]["page-title"]} subtitle={resources[pageLanguageKey].translation["not-found"]["subtitle"]} />
		  	<Latest language={language} />
		</Layout>
	);
}
export default NotFoundPage
*/

import React from 'react';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import Latest from '../components/component.latest';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <HeroBanner title="Not Found" subtitle="You just hit a route that doesn&#39;t exist&hellip;" />
        <Latest />
    </Layout>
);

export default NotFoundPage;